interface IRoutes {
  accounts: {
    createUsers: string;
    manageGroups: string;
    manageLevels: string;
    createGroup: string;
    manageUsers: string;
  };
  login: {
    login: string;
    resetPassword: string;
  };
}

const mockedRoutes: IRoutes = {
  accounts: {
    manageUsers: "/hub/manage-users",
    createUsers: "/hub/create-users",
    manageGroups: "/hub/manage-groups",
    manageLevels: "/hub/manage-levels",
    createGroup: "/hub/create-group",
  },
  login: {
    login: "/",
    resetPassword: "/reset-password",
  },
};

export { mockedRoutes };
