import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *,*::after,*::before{
    box-sizing: border-box;
    margin:0;
    padding:0;
  }

  * {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;

  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  }

  html {
    scroll-behavior: smooth;

  }

  :root {
  --primary: #42c1c7;
  --secondary: #521aa3;

  --success: #24a148;
  --error: #da1e28;

  --white: #ffffff;
  --gray1: #f4f4f4;
  --gray2: #e0e0e0;
  --gray3: #a8a8a8;
  --gray4: #6f6f6f;
  --gray5: #393939;
  --gray6: #1a1a1a;
}

  body {
    font-family: "Roboto", sans-serif;
    width:100vw;
    height:100vh;
    overflow:auto;
}

`;

export default GlobalStyle;
