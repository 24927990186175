import * as Styles from "./styles";
import * as Interface from "../Button.structure";

const PrimaryButton = ({
  text,
  onClick,
  disable,
  color,
}: Interface.IButton) => {
  return (
    <div>
      <Styles.Button
        data-testid="PrimaryButton"
        onClick={onClick}
        disabled={disable}
        color={color}
      >
        <span>{text}</span>
      </Styles.Button>
    </div>
  );
};

export default PrimaryButton;
