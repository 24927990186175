import styled, { keyframes } from "styled-components";

const toastIn = keyframes`
  to {
    opacity: 1;
  }
  from {
      opacity: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    (props.color === "success" && "var(--primary)") ||
    (props.color === "error" && "var(--error)") ||
    (props.color === "warning" && "var(--error)")};
  width: 20%;
  min-height: 35px;
  max-height: 70px;
  font-size: 0.8rem;
  color: var(--white);
  border-radius: 0 0 8px 8px;
  cursor: pointer;
  font-weight: bold;
  padding: 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  animation: ${toastIn} 1s;

  @media (max-width: 1400px) {
    width: 23%;
  }
`;

export const IconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: fit-content;
`;

export const LabelArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 10px;
  width: fit-content;
`;
