import { IStatesSelectProps } from "./DropDown.structure";
import React from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import * as Styles from "./Styles";

export default function StatesSelect({
  states,
  setState,
  selectedState,
  hasError,
}: IStatesSelectProps) {
  const [isOpened, setIsOpen] = React.useState<boolean>(false);

  const showSelectedState = React.useCallback((selectedOption) => {
    const selectedState = states.find((state) => state.value === selectedOption);
    return selectedState ? selectedState.text : 'Nome';
  },[selectedState])
  const MapStatesSelect = React.useMemo(
    () =>
      states?.map((state) => {
        return (
          <Styles.Item key={state.value} onClick={() => {
            setIsOpen(false)
            setState(state.value)
          }}>
              <p>{state.text}</p>
            </Styles.Item>
        );
      }),
    [states]
  );

  return (
    <Styles.Container hasError={hasError} data-testid="states-select">
      {hasError && <Styles.Error>Estado necessário</Styles.Error>}
      <Styles.Placeholder isOpened={isOpened} hasError={hasError}>
        <div>{showSelectedState(selectedState)}</div>
        <MdOutlineKeyboardArrowDown
          size={30}
          color={`${hasError ? "var(--hasError)" : "var(--gray4)"}`}
          onClick={() => {
            setIsOpen(!isOpened);
          }}
        />
      </Styles.Placeholder>
      {isOpened && (
        <Styles.Options id="options">{MapStatesSelect}</Styles.Options>
      )}
    </Styles.Container>
  );
}
