import React from "react";
import {  CloseIcon, Container, Content, GroupsContainer, Header, InputContainer, Modal, ModalButton, ModalContent, ModalHeader, ModalInput, NewGroupButton, SuccessButton, SuccessContainer, SuccessDescription } from "./styles";
import Cookie from 'js-cookie';
import { usePermissions } from "../../contexts/permissionsContext";
import { ErrorType, IGroupedPermissions, INewGroup, RedirectTo } from "./interfaces.structure";
import { IPermission } from "../../interfaces/Permissions.structure";
import GroupCard from "../../components/GroupCard";
import GroupService from "../../services/Group";
import {FaCheck as Check} from 'react-icons/fa';
import { Toasty } from "../../components/Toasty";
import Loading from "../../components/Loading";
import { LinkAnchor } from "../../components/General/styles";
import { groupByClaim } from "../../utils/groupBy";
import { useLocation } from "react-router-dom";


export default function CreateGroup() {
  const {permissions,getGroups} = usePermissions();
  const [groupedPermissions, setGroupedPermissions] = React.useState<IGroupedPermissions>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [hasCreatedGroup, setHasCreatedGroup] = React.useState<boolean>(false);
  const [shouldDisplayModal, setShouldDisplayModal] = React.useState<boolean>(false);
  const [newGroup, setNewGroup] = React.useState<INewGroup>({
    name: '',
    permissions: [],
    logged_user_id: Cookie.get('id'),
    logged_user_password: ''
  });

  const redirectTo = useLocation().state as {redirectTo: RedirectTo};

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name ,value } = event.target;
    setNewGroup((prevValue) =>({...prevValue, [name]: value}))
  }

  function handleSetPermissionFlag(permission: IPermission) {
    const newGroupPermissionCopy = [...newGroup.permissions];
    const permissionIndex = newGroupPermissionCopy.findIndex(item => item.id === permission.id);

    if(permissionIndex === -1) return;

    newGroupPermissionCopy[permissionIndex] = permission;
    setNewGroup(prevValue => ({...prevValue, permissions: newGroupPermissionCopy}))
  }

  function handleSetNewPermissions(permission: IPermission) {
    const hasPermission = (newGroup.permissions.find(item => item.id === permission.id))

    if(hasPermission) {
      const newPermissions = newGroup.permissions.filter(item => item.id !== permission.id);
      setNewGroup(prevValue => ({...prevValue, permissions: newPermissions}))
      return;
    };

    const permissionWithoutFlag = permission.claim.split('.').slice(0,2).join('.');
    const permissionWithFlag = `${permissionWithoutFlag}.view`;
    setNewGroup(prevValue => ({...prevValue, permissions: [...prevValue.permissions, {...permission, claim: permissionWithFlag}]}))
  }

  async function handleCreateGroup() {
    setIsLoading(true);
    const response = await GroupService.createGroup(newGroup);

    if(response.error) {
      showError();
      setErrorMessage('Grupo ja existente');
      setIsLoading(false);
      return;
    }
    console.log('grupo criado com sucesso');
    setHasCreatedGroup(true);
    setIsLoading(false);
    getGroups();
    
  }

  const renderGroups = React.useMemo(() => {
    const entries = Object.entries(groupedPermissions);
    return entries?.map(([key, value]) => {
      return (
        <GroupCard 
          name={key}
          permissions={value}
          key={key}
          onCheckbox={handleSetNewPermissions}
          newGroup={newGroup}
          onRadioClick={handleSetPermissionFlag}
        />
      )
    })
  },[groupedPermissions,newGroup,permissions])

  const showError = () => {
    const timeout = setTimeout(() => {
      setErrorMessage("");
    }, 4000);
    return () => clearTimeout(timeout);
  };

  const toasty = (label: string, type: ErrorType) => (
    <Toasty
      label={label}
      type={type}
      visible={errorMessage !== ""}
      onClick={() => setErrorMessage("")}
    />
  );

  React.useEffect(() => {
    if(permissions){
      const grouped = groupByClaim(permissions);
      setGroupedPermissions(grouped)
    }
  },[permissions]);

  return (
    <Container>
      <Content>
        <Header>
          <span>Criação de grupo</span>
          <h1>Criar grupo de permissões</h1>
        </Header>

        {hasCreatedGroup 
          ? 
            <SuccessContainer>
              <Check size={250} color="#24A148"/>
              <SuccessDescription>
                <h1>Grupo criado com sucesso!</h1>
                <span>Um novo grupo foi criado e já pode ser atribuído aos colaboradores.</span>
                <span>Clique no botão abaixo</span>
              </SuccessDescription>
              <LinkAnchor to={redirectTo?.redirectTo ? redirectTo?.redirectTo : '/hub/manage-users' }>
                <SuccessButton>
                  Atribuir Permissões
                </SuccessButton>
              </LinkAnchor>

            </SuccessContainer>
          : 
            <>
              <InputContainer>
                <label htmlFor="name" >Nome</label>
                <input 
                  type="text" 
                  id="name"
                  name="name"
                  placeholder="Digite aqui o nome do grupo"
                  value={newGroup?.name}
                  onChange={handleChange}
                />
                {newGroup.name.length < 3 && newGroup.name.length > 0 && <span>Grupo precisa ter pelo menos três caracteres</span>}
              </InputContainer>

              <GroupsContainer>
                  {renderGroups}
              </GroupsContainer>

              <NewGroupButton 
                onClick={() => setShouldDisplayModal(true)} 
                disabled={!newGroup.name || newGroup.permissions.length <= 0 || newGroup.name.length < 3}
              >
                Criar novo grupo de permissões
              </NewGroupButton>

              {shouldDisplayModal && (
                <Modal>
                  {toasty(errorMessage, "error")}
                  <ModalContent>
                    <CloseIcon 
                      onClick={() => setShouldDisplayModal(false)} 
                      size={35}
                    />

                    <ModalHeader>
                      <h1>Deseja criar o grupo {newGroup.name}</h1>
                      <span>Ao criar o grupo {newGroup.name}, você poderá imediatamente atribuir o grupo á outros colaboradores.</span>
                    </ModalHeader>

                    <ModalInput>
                      <label htmlFor="password" >Senha</label>
                      <input 
                        type="password" 
                        id="password" 
                        name="logged_user_password"
                        placeholder="Digite aqui a sua senha"
                        value={newGroup.logged_user_password}
                        onChange={handleChange}
                      />
                    </ModalInput>

                    <ModalButton 
                      onClick={handleCreateGroup} 
                      disabled={isLoading}
                    >
                      {isLoading ? <Loading /> : 'Confirmar edição'}
                    </ModalButton>
                  </ModalContent>
                </Modal>
              )}
            </>}
      </Content>
    </Container>
  )
};

