import styled from "styled-components";

import { IRadioStyleProps } from "./interfaces.structure";

export const Container = styled.div`
  height: 20px;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  padding: 0 5px;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
  color: var(--gray6);
  cursor: pointer;
`;

export const RadioButton = styled.div<IRadioStyleProps>`
  height: 16px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 2px solid;
  border-color: ${(props) =>
    props.isActive ? "var(--secondary)" : "var(--gray3)"};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")}
`;

export const RadioCircle = styled.div<IRadioStyleProps>`
  width: 10px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isActive ? "var(--secondary)" : "transparent"};
  transition: 0.5s;
`;
