import styled from "styled-components";

import { ICheckBoxStyleProps } from "./interfaces.structure";

export const Wrapper = styled.div<ICheckBoxStyleProps>`
  min-width: ${(props) => props.size || 15}px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  align-items: center;
  border: 1px solid;
  border-color: ${props => props.isChecked ? 'transparent' : 'var(--gray3)'};
  background-color: ${(props) =>
    props.isChecked ? "var(--secondary)" : "transparent"};
  border-radius: 3px;
  cursor: pointer;
`;
