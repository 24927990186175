import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

export const ContainerSecondary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: var(--gray2);
`;

export const Content = styled.div`
  height: 80%;
`;

export const CheckBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 50vw;
  margin: 20px 0;
`;

export const AccordionContainer = styled.div`
  height: 25rem;
  width: 50vw;
  margin-bottom: 20px;
  padding-right: 10px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
  }
`;
