import React from "react";
import AssignGroup from "../../components/AssignGroup";
import UserPage from "../../components/UsersPage";
import { usePermissions } from "../../contexts/permissionsContext";

export default function ManageUsers() {
  const [step, setStep] = React.useState<number>(1);
  const {groups, permissions,getGroups,selectedUser,setSelectedUser,userPermissions} = usePermissions();

  interface ISteps {
    [key: number]: JSX.Element;
  }
  const Steps: ISteps = {
    1: <UserPage setStep={setStep} setSelectedUser={setSelectedUser} userPermissions={userPermissions}/>,
    2: <AssignGroup 
        groups={groups} 
        permissions={permissions} 
        selectedUser={selectedUser} 
        getGroups={getGroups} 
        setSelectedUser={setSelectedUser} 
        setSteps={setStep} 
        redirectTo='/hub/manage-users'
        headerTitle="Gerenciar perfis"
      />,
  }

  React.useEffect(() => {
    if(selectedUser){
      setStep(2);
      return;
    }
    setStep(1);
  },[selectedUser])

  
return (
  <>{Steps[step]}</>
)
};

