import React from 'react'
import { Container, Next, Page, Pages, Previous } from "./styles";
import { IPaginationProps } from "./interfaces.strucutre";

function Pagination({totalPages = 1,setCurrentPage,currentPage,prev,next}: IPaginationProps) {
  const pages = Array(totalPages).fill(1).map((item, index) => item + index);

  const renderPages = pages.map((page, index) => {
    const isActive = page === (currentPage + 1);
    return (
      <Page 
        key={page}
        onClick={() => setCurrentPage(page - 1)} 
        active={isActive}
      >
        <span>{page}</span>
      </Page>
    )
  });

  return (
    <Container>
      <Previous size={35} onClick={prev}/>
      <Pages>
        {renderPages}
      </Pages>
      <Next size={35} onClick={next}/>
    </Container>
  )
}

export default Pagination;