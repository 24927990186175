import styled, { keyframes }  from "styled-components";

const Spiner = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Container = styled.div`
  max-width:100vw;
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:var(--gray1);
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  width: 50vw;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  gap: 20px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  margin-top: 5px;
  width: 100%;
  min-width: 600px;
  overflow: scroll;

`;
export const HeadLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-width: 600px;
  background: var(--white);
  min-height: 60px;
`;
export const ItemLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-width: 600px;
  background: var(--white);
  min-height: 60px;
  margin: 2px 0;
  border-radius: 8px;
`;
export const ItemColumn = styled.div`
width: 30%;
padding: 0 5px;
display: flex;
align-items: center;
justify-content: center;
color: var(--gray5);
`;
export const linkColumn = styled.div`
width: 10%;
display: flex;
align-items: center;
justify-content: center;
`;
export const Button = styled.div`
height: 40px;
min-width: 120px;
border-radius: 8px;
background: white;
display: flex;
align-items: center;
justify-content: center;
color: var(--gray4);
border : 2px dotted var(--gray2);
cursor: pointer;

:hover	{
  color: var(--gray2);
}
`;

export const SearchButton = styled.div`
background: white;
border-radius: 50%;
min-width: 40px;
min-height: 40px;
display: flex;
align-items: center;
justify-content: center;
color: var(--gray4);
border : 2px dotted var(--gray2);
cursor: pointer;

:hover	{
  color: var(--gray2);
}
`;
