import React from "react";
import { FaCheck, FaTimes, FaExclamationCircle } from "react-icons/fa";

import { INotification, INotificationHashMap } from "./interfaces.structure";
import { Container, IconArea, LabelArea } from "./styles";

export const Toasty: React.FC<INotification> = ({
  label,
  type = "success",
  visible = true,
  onClick,
}) => {
  const HashmapContainer: INotificationHashMap = {
    success: (
      <>
        <IconArea>
          <FaCheck />
        </IconArea>
        <LabelArea>{label}</LabelArea>
      </>
    ),
    error: (
      <>
        <IconArea>
          <FaTimes />
        </IconArea>
        <LabelArea>{label}</LabelArea>
      </>
    ),
    warning: (
      <>
        <IconArea>
          <FaExclamationCircle />
        </IconArea>
        <LabelArea>{label}</LabelArea>
      </>
    ),
  };

  return visible ? (
    <Container color={type} onClick={onClick}>
      {HashmapContainer[type]}
    </Container>
  ) : null;
};
