import React from "react";
import { MdCheck } from "react-icons/md";
import { ICheckBox } from "./Controls.structure";

export const Checkbox: React.FC<ICheckBox> = ({
  disable = false,
  value,
  setValue,
  size = 20,
  label,
}) => {
  const backgroundColor = () => {
    if (value && !disable) {
      return "var(--secondary)";
    }

    if (disable && value) {
      return "var(--gray2)";
    }

    if (disable) {
      return "#fff";
    }

    return "#fff";
  };

  return (
    <label
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <label
        data-testid="label"
        className="toggle-checkbox"
        style={{
          borderRadius: 4,
          transition: "all 0.2s ease",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: size,
          minHeight: size,
          backgroundColor: backgroundColor(),
          border: `1px solid ${value ? "var(--secondary)" : "var(--gray3)"}`,
          borderColor: `${disable && "var(--gray2)"}`,
          cursor: disable ? "default" : "pointer",
        }}
        onClick={() => {
          if (!disable) {
            setValue(!value);
          }
        }}
      >
        {value ? (
          <MdCheck size={size} color={disable ? "var(--gray3)" : "#fff"} />
        ) : null}
      </label>
      <span
        style={{
          marginLeft: 20,
          fontSize: 16,
        }}
      >
        {label}
      </span>
    </label>
  );
};
