import { IUser } from "../interfaces/User.structure";
import api from "./api";

class getUsers {
  /**
   * @description Get all users profile
   * @params Not necessary
   * @returns {Promise<object | false>} An object when requisition is true or false in error
   */
  async getUsers(page: number){
    try {
      const { data } = await api.get(`/profile?page=${page}`);
      return data;
    } catch (error) {
      return {
        error: true,
        message: error.message
      };
    }
  }
  async getFilteredUsers(page: number, field: string, value: string){
    try {
      const { data } = await api.get(`/profile/getAllProfileByFilter?field=${field}&value=${value}&page=${page}`);
      return data;
    } catch (error) {
      return {
        error: true,
        message: error.message
      };
    }
  }
}

export default new getUsers();
