import styled from "styled-components";
import {IoIosArrowForward, IoIosArrowBack} from 'react-icons/io';
import { IPageStyleProps } from "./interfaces.strucutre";


export const Container = styled.div`
  max-width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Pages = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;

`

export const Page = styled.span<IPageStyleProps>`
  font-size: ${props => props.active ? '1.1rem' : '1rem'};
  font-weight:bold;
  font-family: 'Roboto', sans-serif;
  color: ${props => props.active ? 'var(--primary)' : 'var(--gray5)'};
  text-decoration: ${props => props.active ? 'underline' : 'none'};
  cursor: pointer;
  transition: all 0.3s;

`

export const Next = styled(IoIosArrowForward)`
  cursor: pointer;
`
export const Previous = styled(IoIosArrowBack)`
  cursor: pointer;
`