import { MdCheck as Check } from "react-icons/md";

import { ICheckBoxProps } from "./interfaces.structure";
import { Wrapper } from "./styles";

const CheckBox = ({
  size = 20,
  isChecked,
  onCheck,
}:ICheckBoxProps) => {
  return (
    <Wrapper
      data-testid="checkbox"
      isChecked={isChecked}
      size={size}
      onClick={() => onCheck(!isChecked)}
    >
      {isChecked && <Check color="white" size={15} />}
    </Wrapper>
  );
};

export default CheckBox;
