import React from "react";
import CreateProfile from "../../components/CreateProfile";
import AssignGroup from "../../components/AssignGroup";
import { usePermissions } from "../../contexts/permissionsContext";
import { ICreateUserResponse, IUser } from "../../interfaces/User.structure";

export default function CreateUserProfile()  {
  const [steps, setSteps] = React.useState<number>(1);
  const {groups,permissions,getGroups,newUser,setNewUser } = usePermissions();
  
  const createProfileSteps = {
    1: <CreateProfile setSteps={setSteps} setSelectedUser={setNewUser}/>,
    2: <AssignGroup groups={groups} permissions={permissions} selectedUser={newUser} getGroups={getGroups} setSelectedUser={setNewUser} setSteps={setSteps} redirectTo='/hub/create-users'/>,
  }

  React.useEffect(() => {
    if(newUser){
      setSteps(2);
    }
  },[newUser])

  return (
    <>
      {createProfileSteps[steps]}
    </>
  );
};

