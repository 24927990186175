import * as Interface from "../Inputs.structure";
import * as Styles from "./styles";

const EmailInput = ({
  label,
  onChange,
  value,
  placeholder,
}: Interface.IInput) => {
  const error = !!(!/^[A-Za-z0-9._%+-]+@qesh\.ai$/.test(value) && value);
  return (
    <Styles.Container>
      <Styles.Title>{label}</Styles.Title>
      <Styles.Input
        value={value.replace(" ", "")}
        onChange={onChange}
        error={error}
        placeholder={placeholder}
        maxLength={40}
      />
      {error && (
        <Styles.HelperText data-testid="helper">
          E-mail deve conter "@qesh.ai!"
        </Styles.HelperText>
      )}
    </Styles.Container>
  );
};

export default EmailInput;
