import { Button, CloseIcon, Modal, ModalButton, ModalContent, ModalDescription, ModalDescriptionContainer, ModalHeader, ModalInput, ModalSearch, Permission, PermissionCheckbox, PermissionModal, PermissionRadios, Permissions, PermissionsContainer, PermissionType, SuccessButton, SuccessContainer, SuccessDescription } from "./styles";
import {BiSearch as Search} from 'react-icons/bi';
import Cookie from 'js-cookie';
import { IPermissionModal } from "./interfaces.structure";
import React from "react";
import CheckBox from "../Inputs/Checkbox";
import { Radio } from "../Inputs/Radio";
import { IPermissions } from "../../interfaces/Group.structure";
import GroupService from '../../services/Group'
import {FaCheck as Check} from 'react-icons/fa';
import Loading from "../Loading";

const PermissionsModal = ({permissions,setShowModal,group,getGroups,currentPage}: IPermissionModal) => {
  const [steps, setSteps] = React.useState<number>(1);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [ownedPermissions, setOwnedPermissions] = React.useState<IPermissions[]>(group.permissions);
  const [password, setPassword] = React.useState<string>('');
  const [search, setSearch] = React.useState<string>('');

  function handlePermissionChange(permission:IPermissions, flag: 'view' | 'edit'){
    const permissionWithoutFlag = permission.claim.split('.').slice(0,2).join('.');
    const ownedPermissionsCopy = [...ownedPermissions]; 

    const ownedPermissionIndex = ownedPermissionsCopy.findIndex(item => {
      const ownedItemWithoutFlag = item.claim.split('.').slice(0,2).join('.');

      return ownedItemWithoutFlag === permissionWithoutFlag
    });

    if(ownedPermissionIndex >= 0) {
      const ownedPermission = ownedPermissionsCopy[ownedPermissionIndex];
      const updatedPermission = `${permissionWithoutFlag}.${flag}`;
      
      ownedPermissionsCopy[ownedPermissionIndex] = {...ownedPermission, claim: updatedPermission}
      setOwnedPermissions(ownedPermissionsCopy);
      return;
    }
    
    ownedPermissionsCopy.push({...permission, claim: `${permissionWithoutFlag}.${flag}`});
    setOwnedPermissions(ownedPermissionsCopy);
  }

  const getActiveState = React.useCallback((ownedPermissions: IPermissions[],permission: IPermissions, flag: 'view' | 'edit') => {
    const ownedPermission = ownedPermissions.find(item => {
      const permissionWithoutFlag = permission.claim.split('.').slice(0,2).join('.');
      const ownedItemWithoutFlag = item.claim.split('.').slice(0,2).join('.');

      return ownedItemWithoutFlag === permissionWithoutFlag
    });

    if(!ownedPermission) return false;

    const ownedPermissionWithoutFlag = ownedPermission.claim.split('.').slice(0,2).join('.');
    const updatedPermission = `${ownedPermissionWithoutFlag}.${flag}`;

    return ownedPermission.claim === updatedPermission;
  },[ownedPermissions])

  const handleCheckPermission = React.useCallback((permission: IPermissions) => {
    const permissionIndex = ownedPermissions.findIndex(item => item.id === permission.id);
    if(permissionIndex === -1) {
      setOwnedPermissions(prevValue => [...prevValue, permission]);
      return;
    };

    setOwnedPermissions(prevValue => prevValue.filter(item => item.id !== permission.id));
  },[ownedPermissions])

  async function handleUpdatePermissions() {
    const id = Cookie.get('id');
    setIsLoading(true);
    const response = await GroupService.patchGroup({
      name: group.name,
      permissions: ownedPermissions,
      logged_user_id: id,
      logged_user_password: password
    },group.id);

    if(response.error) {
      setIsLoading(false);
      return;
    };
    await getGroups(currentPage);
    setIsLoading(false);
    setSteps(3);
    
  }

  const renderPermissions = React.useMemo(() => {
    return permissions
    ?.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
    .map((item,index) => {
      const lastItem = index === 0
      const firstItem = (index + 1) === permissions.length;
      const isOdd = (index % 2) !== 0;
      const isChecked = !!(ownedPermissions.find(permission => permission.id === item.id));
      return (
        <Permission isOdd={isOdd} isFirst={firstItem} isLast={lastItem} key={item?.id}>
          <PermissionCheckbox>
            <CheckBox isChecked={isChecked} onCheck={() => handleCheckPermission(item)}/>
            <span>{item.name}</span>
          </PermissionCheckbox>
          <PermissionRadios>
            <Radio 
              isActive={isChecked ? getActiveState(ownedPermissions,item,'view') : false} 
              handleClick={() => handlePermissionChange(item,'view')}
              isDisabled={!isChecked}
            />
            <Radio 
              isActive={isChecked ? getActiveState(ownedPermissions,item,'edit') : false} 
              handleClick={() => handlePermissionChange(item, 'edit')}
              isDisabled={!isChecked}
            />
          </PermissionRadios>
        </Permission>
      )
  })
  },[search,permissions,ownedPermissions])

  const stepsHashmap = {
    1:       
    <PermissionModal>
      <CloseIcon 
        size={30}
        onClick={() => setShowModal(false)}
      />
      <ModalDescriptionContainer>
        <h1>{group.name}</h1>
        <ModalDescription>
          <span>Ao editar as permissões do grupo {group.name}, você não afetará outros colaboradores.</span>
        </ModalDescription>
        <ModalSearch >
          <Search size={25}/>
          <input 
            placeholder='Digite aqui o nome da permissão'
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </ModalSearch>
      </ModalDescriptionContainer>

      <PermissionsContainer>
        <PermissionType>
          <span>Somente visualizar</span>
          <span>Editar</span>
        </PermissionType>
        <Permissions>
          {renderPermissions}
        </Permissions>
        <Button onClick={() => setSteps(2)} isDisabled={!group.name || ownedPermissions.length <= 0}>Editar permissões</Button>

      </PermissionsContainer>
    </PermissionModal>,
    2: 
    <ModalContent>
      <CloseIcon 
        onClick={() => setShowModal(false)} 
        size={35}
      />

      <ModalHeader>
        <h1>Deseja editar as permissões do grupo {group.name}</h1>
        <span>Ao editar as permissões do grupo {group.name}, você não afetará outros colaboradores. Digite sua senha para confirmar</span>
      </ModalHeader>

      <ModalInput>
        <label htmlFor="password" >Senha</label>
        <input 
          type="password" 
          id="password" 
          placeholder="Digite aqui a sua senha"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </ModalInput>

      <ModalButton 
        onClick={handleUpdatePermissions} 
        disabled={isLoading}
      >
        {isLoading ? <Loading /> : 'Confirmar edição'}
      </ModalButton>
    </ModalContent>,
    3: 
    <SuccessContainer>
      <Check size={250} color="#24A148"/>
      <SuccessDescription>
        <h1>Permissões editadas com sucesso!</h1>
        <span>Outros colaboradores não foram afetados.</span>
        <span>Se quiser alterar as permissões de outro colaborador, busque o perfil e repita o processo.</span>
      </SuccessDescription>
      <SuccessButton onClick={() => setShowModal(false)}>Fechar</SuccessButton>

    </SuccessContainer>,
  }

  return (
    <Modal>
      {stepsHashmap[steps]}
    </Modal>
  );
};

export default PermissionsModal;
