import styled from "styled-components"
import {GrFormClose as Close} from 'react-icons/gr'
import { IButton } from "./interfaces.structure"
import { IPermissionStyle } from "../../pages/CreateGroup/interfaces.structure"

export const CloseIcon = styled(Close)`
  position:absolute;
  top:10px;
  right:10px;
  cursor:pointer;
`

export const Modal = styled.div`
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);
  display:flex;
  justify-content:center;
  align-items:center;
  position:fixed;
  
`

export const ModalContent = styled.div`
  width:750px;
  height:570px;
  max-height: 80%;
  background-color:var(--white);
  border-radius:15px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding: 50px 0 20px 0;
  gap: 100px;
  position:relative;
`


export const PermissionModal = styled.div`
  width:55%;
  height:85%;
  background-color: var(--white);
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  gap: 25px;
  border-radius:10px;
  padding: 20px 50px;
  position:relative;

  @media screen and (max-height: 850px) {
    height:95%;
  }

  @media screen and (max-width: 1250px) {
    width:70%;
  }

`

export const ModalDescriptionContainer = styled.div`
  width:100%;
  max-height:35%;
  height:fit-content;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
  gap:10px;

  & > h1 {
    font-size: clamp(1rem, 2vw, 1.3rem);
    font-weight:700;
    font-family: 'Fira Sans', sans-serif;
    margin:0;
    color: var(--gray5);
  }

  & > span {
    font-size: clamp(0.6rem, 1vw, 0.7rem);
  }
`

export const ModalDescription = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  font-family: 'Roboto', sans-serif;
  color: var(--gray5);

  & > p {
    font-weight:bold;
  }
`

export const ModalSearch = styled.div`
  width: 40%;
  height: 45px;
  border-radius:8px;
  border: 1px solid var(--gray3);
  margin-top:30px;
  display:flex;
  justify-content: flex-start;
  align-items:center;
  padding: 0 15px;
  gap:10px;
  font-family: 'Roboto', sans-serif;

  & > input {
    color: var(--gray3);
    font-size: 0.8rem;
    font-weight:bold;
    font-family: 'Roboto', sans-serif;
    border:none;
    outline:none;
    background-color:transparent;
    width:100%;
    height:100%;

    &::placeholder {
      color: var(--gray4);
      font-size: 0.7rem;
      font-weight:bold;
    }
  }
`

export const PermissionsContainer = styled.div`
  width:100%;
  height:70%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  gap:10px;

  @media screen and (max-height: 750px) {
    height: 60%;
  }
`

export const PermissionType = styled.div`
  width:90%;
  height: fit-content;
  display:flex;
  justify-content:flex-end;
  align-items:center;
  gap:50px;
  padding: 0 15px 0 0;

  & > span {
    color: var(--gray5);
    font-size: 0.8rem;
    font-weight:bold;
    font-family: 'Roboto', sans-serif;
  }
`

export const Permissions = styled.div`
  width:100%;
  height: fit-content;
  max-height:75%;
  min-height:40px;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  overflow-y:scroll;
`
export const Permission = styled.div<IPermissionStyle>`
  width:100%;
  height:40px;
  background-color: ${props => props.isOdd ? 'transparent' : 'var(--gray2)'};
  border-top-left-radius: ${props => props.isFirst ? '8px' : '0'};
  border-top-right-radius: ${props => props.isFirst ? '8px' : '0'};
  border-bottom-right-radius: ${props => props.isLast ? '8px' : '0'};
  border-bottom-left-radius: ${props => props.isLast ? '8px' : '0'};
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding: 0 50px 0 15px; 
`

export const PermissionCheckbox = styled.div`
  min-width:20%;
  width:fit-content;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  gap:80px;
  color: var(--gray5);
  & > span {
    font-size: 0.8rem;
    white-space: nowrap;
  }

`

export const PermissionRadios = styled.div`
  width:fit-content;
  height:100%;
  display:flex;
  justify-content:center;
  gap:105px;
  align-items:center;
`

export const Button = styled.div<IButton>`
  width:180px;
  height:40px;
  background-color: ${props => props.isDisabled ? 'var(--gray2)' : 'var(--primary)'};
  border-radius:8px;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight:bold;
  font-size: 0.8rem;
  align-self: flex-end;
`

export const ModalHeader = styled.div`
  width:100%;
  height:fit-content;
  max-height:10%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  gap:20px;
  color: var(--gray5);

  & > h1 {
    font-size:1.5rem;
    font-weight:bold;
    margin:0;
    max-width:45%;
    text-align:center;
  }

  & > span {
    font-size:1rem;
    width:85%;
  }
`

export const ModalInput = styled.div`
  width:45%;
  height:fit-content;
  max-height:15%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  gap:10px;

  & > input {
    width:100%;
    height:45px;
    border-radius:8px;
    border:1px solid var(--gray3);
    padding:0 10px;
    font-size:1rem;
    font-family:'Roboto',sans-serif;
    outline:none;

    &:placeholder {
      color:#a8a8a8;
    }
  }

  & > label {
    font-size:0.8rem;
    font-family:'Roboto',sans-serif;
    font-weight:bold;

  }
`

export const ModalButton = styled.button`
  width: 40%;
  height: 50px;
  border-radius: 8px;
  background-color: var(--success);
  color: var(--white);
  outline: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SuccessContainer = styled.div`
  width:40%;
  height:fit-content;
  height:70%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  gap:20px;
  padding: 30px;
  background-color: var(--white);
  border-radius: 15px;
`

export const SuccessDescription = styled.div`
  max-width:65%;
  height:45%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  gap:30px;
  color: var(--gray5);
  & > h1 {
    font-size:1.5rem;
    font-weight:bold;
    margin:0;
    width:fit-content;
    font-family: 'Fira-Sans', sans-serif;
    text-align:center;
  }

  & > span {
    font-size:1rem;
    text-align:center;
    font-family: 'Roboto', sans-serif;
  }
`

export const SuccessButton = styled.button`
  width: 40%;
  height: 50px;
  border-radius: 8px;
  background-color: var(--success);
  color: var(--white);
  outline: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
`