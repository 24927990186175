import React from "react";
import * as GlobalStyles from "../../components/General/styles";
import * as Styles from "./styles";
import SearchInput from "../../components/General/Inputs/SearchInput";
import DropDown from "../../components/General/Inputs/DropDown";
import { BiSearch } from "react-icons/bi";
import { IUsers, IUsersPageProps } from "./RegisterUser.structure";
import { MdArrowForwardIos } from "react-icons/md";
import GetUsers from "../../services/GetUsers";
import { usePagination } from "../../hooks/usePagination";
import Pagination from "../../components/Pagination";

const UserPage = ({setStep,setSelectedUser,userPermissions}: IUsersPageProps) => {
  const [selectedFilter, setSelectedFilter] = React.useState<
    "name" | "email" | "position"
  >("name");
  const [searchValue, setSearchValue] = React.useState<string>("none");
  const [inputValue, setInputValue] = React.useState<string>("");
  const [totalUsers, setTotalUsers] = React.useState<number>(0);
  const { currentPage, totalPages, changePage, backward, forward } =
    usePagination({ totalItems: totalUsers, pageSize: 10 });

  const [displayedList, setDisplayedList] = React.useState<IUsers[]>([]);
  const hasEditPermission = userPermissions.find(item => item === 'Administrador.Usuário.edit');

  const getUsers = async () => {
    const response = await GetUsers.getUsers(currentPage);
    if (!response) return
    setDisplayedList(response.users_per_page);
    setTotalUsers(response.full_number_users);
  };

  function cleanFilter() {
    setSearchValue("none")
    setInputValue('')
  }

  const getFilteredUsers = async () => {
    changePage(0);
    const response = await GetUsers.getFilteredUsers(
      currentPage,
      selectedFilter,
      searchValue
    );
    if (!response) return console.log("error");
    setDisplayedList(response.profiles);
    setTotalUsers(response.full_number_users);
  };

  React.useEffect(() => {
    if (searchValue === "none") getUsers();
    else getFilteredUsers();
  }, [searchValue, currentPage]);

  React.useCallback(() => {
    setSearchValue('none')
  }, [selectedFilter])
  
  const usersList = React.useMemo(() => {
    return displayedList?.map((user) => (
      <Styles.ItemLine key={user.id}>
        <Styles.ItemColumn>{user.name}</Styles.ItemColumn>
        <Styles.ItemColumn>{user.email}</Styles.ItemColumn>
        <Styles.ItemColumn>{user.position}</Styles.ItemColumn>
        <Styles.linkColumn
          style={{ cursor: "pointer" }}
          onClick={hasEditPermission ? () => {
            setSelectedUser(user)
            setStep(2)
          } : () => {}}
        >
            {hasEditPermission && <MdArrowForwardIos />}
        </Styles.linkColumn>
      </Styles.ItemLine>
    ));
  }, [displayedList,userPermissions]);

  return (
    <GlobalStyles.Container>
      <GlobalStyles.TitleContainer>
        <GlobalStyles.Title>Gerenciar perfis</GlobalStyles.Title>
        <GlobalStyles.SubTitle>
          Filtre o perfil que deseja encontrar por nome, e-mail ou cargo
        </GlobalStyles.SubTitle>

        <Styles.Header>
          <DropDown
            states={[
              { value: "name", text: "Nome" },
              { value: "email", text: "E-mail" },
              { value: "position", text: "Cargo" },
            ]}
            setState={setSelectedFilter}
            selectedState={selectedFilter}
          />
          <SearchInput
            label=""
            onChange={(event) => setInputValue(event.target.value)}
            value={inputValue}
            placeholder={"Buscar"}
          />
          <Styles.SearchButton onClick={() => setSearchValue(inputValue)}>
            <BiSearch />
          </Styles.SearchButton>
          <Styles.Button onClick={cleanFilter}>
            Limpar filtros
          </Styles.Button>
        </Styles.Header>
      </GlobalStyles.TitleContainer>

      <Styles.Content>
        <Styles.HeadLine style={{ fontWeight: "bold" }}>
          <Styles.ItemColumn>Nome</Styles.ItemColumn>
          <Styles.ItemColumn>E-mail</Styles.ItemColumn>
          <Styles.ItemColumn>Cargo</Styles.ItemColumn>
          <Styles.linkColumn />
        </Styles.HeadLine>
        <Styles.List>{displayedList && usersList}</Styles.List>
      </Styles.Content>
      <Pagination
        totalPages={totalPages}
        setCurrentPage={changePage}
        currentPage={currentPage}
        next={forward}
        prev={backward}
      />
    </GlobalStyles.Container>
  );
};

export default UserPage;
