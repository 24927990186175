import * as Interface from "../Inputs.structure";
import * as Styles from "./styles";

const TextInput = ({
  label,
  onChange,
  value,
  placeholder,
  error,
  helper,
}: Interface.IInput) => {
  return (
    <Styles.Container>
      <Styles.Title>{label}</Styles.Title>
      <Styles.Input
        value={value}
        onChange={onChange}
        error={error}
        placeholder={placeholder}
        maxLength={40}
      />
      {error && (
        <Styles.HelperText data-testid="helper">{helper}</Styles.HelperText>
      )}
    </Styles.Container>
  );
};

export default TextInput;
