import styled from "styled-components";

import { FaCheck } from "react-icons/fa";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  max-width: 100vw;
  padding: 20px;
`;

export const Header = styled.div`
  width:100%;
  height:fit-content;
  max-height:10%;
  gap:20px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:flex-start;
  font-family: 'Fira Sans', sans-serif;
  margin-bottom:20px;

  & > h1 {
    font-size: 1.5rem;
    margin:0;
    font-weight:bold;
  }

  & > span {
    font-size: 1.25rem;
    font-weight:bold;
  }
`

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 80%;
`;

export const Check = styled(FaCheck)`
  color: var(--success);
  font-size: 15rem;

  @media screen and (max-width: 1350px) {
    font-size: 10rem;
  }
`;

export const TextParagraph = styled.span`
  width: 50%;
  font-size: 1.1rem;
  text-align: center;
`;

export const ImportantText = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
`;
