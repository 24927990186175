import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow-x: auto;
  background: var(--gray1);
  color: var(--gray5);
  gap: 14px;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: 80%;
  background: #fff;
  border-radius: 15px;
  min-width: 400px;
  gap: 8%;

  @media screen and (max-width: 1600px) {
    width:65%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: max-content;
  margin-bottom: 1vh;
  color: var(--gray5);
  gap: 7px;
`;

export const Title = styled.h1`
  font-size: 1.3em;
  margin: 0;
  color: var(--gray5);
`;
export const SubTitle = styled.h2`
  font-size: 1.5em;
  margin: 0;
  color: var(--gray5);
`;

export const LinkAnchor = styled(Link)`
  text-decoration: none;
` 
