import React from "react";
import { IProps } from "../interfaces/IPermissionContext.structure";
import { IGroupResponse, IGroups, IPermissionResponse, IPermissions, IUserPermission } from "../interfaces/Group.structure";
import { IPermissionsContext } from "../interfaces/Permissions.structure";
import GroupService from '../services/Group';
import PermissionsService from '../services/Permissions';
import { ICreateUserResponse, IUser } from "../interfaces/User.structure";
import Cookie from 'js-cookie'
import GetUsers from "../services/GetUsers";

const PermissionContext = React.createContext({} as IPermissionsContext);

const PermissionContextProvider: React.FC<IProps> = ({ children }) => {
  const [permissions, setPermissions] = React.useState<IPermissions[]>([]);
  const [groups, setGroups] = React.useState<IGroupResponse>({} as IGroupResponse);
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<IUser | ICreateUserResponse>();
  const [newUser, setNewUser] = React.useState<IUser | ICreateUserResponse>();
  const [userPermissions, setUserPermissions] = React.useState<string[]>([]);

  function permissionFormatter(permissions:IUserPermission[]) {
    const formattedPermissions = permissions.map(permission => {
      if(userPermissions.includes(permission.claim)) return;
      setUserPermissions(prevValue => [...prevValue, permission.claim])

      return
    })
    return formattedPermissions;
  }

  async function getUserPermissions(id:string) {
    const response = await PermissionsService.getUserPermissions(id);

    if(!response)return;

    permissionFormatter(response);

  }

  async function getGroups(page = 0){
    const response = await GroupService.getGroups(page);

    if(!response) return;
    setGroups(response)
  }

  async function getUsers(){
    const response = await GetUsers.getUsers(1);
  if(!response) return;
    setUsers(response);
  }
  
  async function getPermissions(){
    const response = await PermissionsService.getPermissions();

    if(!response) return;

    setPermissions(response)
  }

  React.useEffect(() => {
    getPermissions()
    getUserPermissions(Cookie.get('id'))
    getGroups()
    getUsers()
  }, [])



  return (
    <PermissionContext.Provider
      value={React.useMemo(
        () => ({
          permissions,
          setPermissions,
          groups,
          getGroups,
          users,
          selectedUser,
          setSelectedUser,
          newUser,
          setNewUser,
          userPermissions
        }),
        [permissions, setPermissions, groups, users,selectedUser,newUser,userPermissions]
      )}
    >
      {children}
    </PermissionContext.Provider>
  );
};

function usePermissions() {
  const context = React.useContext(PermissionContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}

export { PermissionContext, PermissionContextProvider, usePermissions };

