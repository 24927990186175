import { IUsePaginationPayload, IUsePaginationProps } from "./interfaces.structure";
import React from 'react'


export function usePagination({
  totalItems,
  initialPage = 0,
  pageSize = 10,
  behavior = 'ceil'
}: IUsePaginationProps): IUsePaginationPayload {
  const [currentPage, setCurrentPage] = React.useState(initialPage);

  const totalPages = Math[behavior](totalItems / pageSize);
  const nextEnabled = currentPage < totalPages;
  const previousEnabled = currentPage > 1;
  const startIndex = currentPage === 1 ? 0 : (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize - 1;

  function backward() {
    if (currentPage > 0) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  }

  function forward() {
    if ((currentPage + 1) < totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  }

  function changePage(page: number) {
    if (page >= 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  }

  return {
    currentPage,
    totalPages,
    nextEnabled,
    previousEnabled,
    startIndex,
    endIndex,
    backward,
    forward,
    changePage,
  };
}