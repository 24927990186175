import React from "react";
import * as GlobalStyles from "../../components/General/styles";
import PrimaryButton from "../../components/General/Buttons/PrimaryButton";
import CpfInput from "../../components/General/Inputs/Cpfinput";
import EmailInput from "../../components/General/Inputs/EmailInput";
import NameInput from "../../components/General/Inputs/NameInput";
import TextInput from "../../components/General/Inputs/TextInput";
import * as Styles from "./styles";
import { Iuser } from "../../pages/CreateUserProfile/RegisterUser.structure";
import ProfileService from "../../services/Profile";
import { Toasty } from "../../components/Toasty";
import { ErrorType } from "../../pages/CreateGroup/interfaces.structure";
import { ICreateProfileProps } from "./interfaces.structure";
import { ICreateUserResponse, IUser } from "../../interfaces/User.structure";

const CreateProfile = ({setSteps,setSelectedUser}: ICreateProfileProps) => {


  const [userInfo, setUserInfo] = React.useState({} as Iuser);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [response, setResponse] = React.useState<IUser | ICreateUserResponse>();
  const [success, setSuccess] = React.useState<boolean>(false);

  const isDisabled = [
    userInfo?.name.length <= 0 
    || userInfo?.email.length <= 0 
    || userInfo?.position.length <= 0].every((item) => item);


  async function handleSubmit() {
    const response = await ProfileService.createProfile(userInfo);

    if(response.error) {
      showError();
      setErrorMessage('Email já cadastrado')
      return;
    }
  
    setSuccess(true);
    setResponse(response)
    
  }

  function handleNextStep() {
    setSelectedUser(response)
    setSteps(2)
  }

  const showError = () => {
    const timeout = setTimeout(() => {
      setErrorMessage("");
    }, 4000);
    return () => clearTimeout(timeout);
  };

  const toasty = (label: string, type: ErrorType) => (
    <Toasty
      label={label}
      type={type}
      visible={errorMessage !== ""}
      onClick={() => setErrorMessage("")}
    />
  );

  return (
    <GlobalStyles.Container>
      {toasty(errorMessage, "error")}
      <GlobalStyles.TitleContainer>
        <GlobalStyles.Title>Criação de Conta</GlobalStyles.Title>
        <GlobalStyles.SubTitle>
          Criar perfil de colaborador
        </GlobalStyles.SubTitle>
      </GlobalStyles.TitleContainer>

      <GlobalStyles.Content>
        {success ? (
          <Styles.Container>
            <Styles.Check />
            <GlobalStyles.SubTitle>
              Perfil criado com sucesso!
            </GlobalStyles.SubTitle>
            <Styles.TextParagraph>
              Um convite foi enviado para o e-mail{" "}
              <Styles.ImportantText>{userInfo?.email || 'colaborador@qesh.ai'}</Styles.ImportantText>
            </Styles.TextParagraph>
            <Styles.TextParagraph>
              Você pode encaixar o colaborador em algum grupo com as permissões
              adequadas.
            </Styles.TextParagraph>

            <PrimaryButton
              text="Atribuir permissões"
              color="var(--success)"
              onClick={handleNextStep}
            />
          </Styles.Container>
        ) : (
          <Styles.Container>
            <Styles.Inputs>
              <NameInput
                label="Nome"
                onChange={(event) =>
                  setUserInfo({ ...userInfo, name: event.target.value })
                }
                value={userInfo.name}
                placeholder={"Insira aqui o nome do colaborador"}
              />
              <EmailInput
                label="E-mail"
                onChange={(event) =>
                  setUserInfo({ ...userInfo, email: event.target.value })
                }
                value={userInfo.email}
                placeholder={"Insira aqui o e-mail do colaborador"}
              />
              <TextInput
                label="Cargo"
                onChange={(event) =>
                  setUserInfo({ ...userInfo, position: event.target.value })
                }
                value={userInfo.position}
                placeholder={"Insira aqui o cargo do colaborador"}
              />
            </Styles.Inputs>

            <PrimaryButton
              text="Criar perfil"
              disable={isDisabled}
              onClick={handleSubmit}
            />
          </Styles.Container>
        )}
      </GlobalStyles.Content>
    </GlobalStyles.Container>
  );
};

export default CreateProfile;
