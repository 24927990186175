import styled, { keyframes } from "styled-components";

const In = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const accordionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 1rem;
`;

export const valueContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  background: #fff;
  padding: 0.5rem 1rem 0 1rem;
`;

export const accordionTitleContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const accordionTitleSubContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3rem;
  align-items: center;
  justify-content: space-between;
`;

export const iconContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17%;
`;

export const divider = styled.div`
  height: 0.1rem;
  margin: 0.5rem 1rem;
  background-color: var(--gray2);
`;

export const accordionSubContent = styled.div`
  display: flex;
  padding: 0 1rem;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  animation: ${In} 0.3s linear;
`;

export const alertAccordionContent = styled.span`
  display: flex;
  color: var(--error);
  font-size: 0.9em;
  align-items: center;
  gap: 20px;
`;

export const bottom = styled.div`
  width: 100%;
  height: 1rem;
  background: #fff;
  border-radius: 0 0 10px 10px;
`;
