import styled, { keyframes } from "styled-components";

interface StatesSelectProps {
  isOpened?: boolean;
  hasError?: boolean;
}

const RotateOpen = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    trasnform: rotate(180deg);
  }
`;

const RotateClose = keyframes`
  0% {
    trasnform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

export const Container = styled.div<StatesSelectProps>`
  position: ${({ isOpened }) => (isOpened ? "absolute" : "relative")};
  width: 90%;
  max-width: 10rem;
  height: 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--white);
  transition: 0.2s ease-in-out;
  border: ${({ hasError }) =>
    hasError ? "2px solid var(--error)" : "1px solid var(--gray2)"};
  border-bottom: none;
`;
export const Placeholder = styled.div<StatesSelectProps>`
  width: 10rem;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 13px;
  font-size: 0.875rem;
  color: var(--gray4);
  text-transform: capitalize;
  background-color: var(--white);
  border-right: 1px solid var(--gray2);
  border-left: 1px solid var(--gray2);
  border-bottom: 1px solid var(--gray2);

  div {
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .boxInput {
    width: 80%;
    height: 20px;
    border-bottom: 1px solid var(--gray4);
  }

  input {
    width: 100%;
    height: 100%;
    font-size: 0.875rem;
    color: ${({ hasError }) => (hasError ? "var(--error)" : "var(--gray4)")};
    text-transform: capitalize;
    border: none;
    outline: none;
  }

  input::placeholder {
    color: ${({ hasError }) => (hasError ? "var(--error)" : "var(--gray4)")};
  }

  input:focus {
    outline: bottom;
  }

  p {
    font-size: 0.875rem;
    color: var(--gray4);
    text-transform: capitalize;
  }

  svg {
    transform: ${({ isOpened }) =>
      isOpened ? "rotate(180deg)" : "rotate(0deg)"};
    animation: ${({ isOpened }) => (isOpened ? RotateOpen : RotateClose)} 0.2s
      ease-in-out;
    cursor: pointer;
    transition-fill-mode: forwards;
  }
`;

export const Options = styled.div`
border-top: 1px solid var(--gray2);
  position: absolute;
  transform: translateY(44px);
  width: 10rem;
  max-height: 12.5rem;
  background: var(--white);
  overflow: auto;
  border-left: 1px solid var(--gray2);
  border-right: 1px solid var(--gray2);
  border-bottom: 1px solid var(--gray2);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray2);
    border-radius: 6px;
    border: none;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: capitalize;
  padding: 0 13px;
  border-bottom: 1px solid var(--gray2);
  margin: 0;
  gap: 10px;

  cursor: pointer;

  p {
    font-size: 0.9rem;
    color: var(--gray4);
    margin: 0;
  }
`;

export const Error = styled.small`
  position: absolute;
  width: 10rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--error);
  text-align: left;
  transition: 0.2s ease-in-out;
  transform: translateY(-130%);
`;