import api from "./api";

class LoginService {
  async login(email: string, password: string) {
    const body = {
      email,
      password,
    };

    try {
      const { data } = await api.post('/auth/login', body);
      return data;
    } catch (error) {
      return false;
    }
  }
}

export default new LoginService();
