import * as Styles from "./styles";
import * as GlobalStyles from "../../General/styles";
import { Checkbox } from "../Checkbox/Checkbox";
import React from "react";
import Accordion from "../Accordion";

const RegisterPermissionGroup = () => {
  const [isAdmin, setIsAdmin] = React.useState(false);

  const test = [
    { title: "teste0", content: "teste0 content" },
    { title: "teste1", content: "teste1 content" },
    { title: "teste2", content: "teste2 content" },
    { title: "teste3", content: "teste3 content" },
    { title: "teste4", content: "teste4 content" },
    { title: "teste5", content: "teste5 content" },
  ];

  const mapAccordion = React.useMemo(() => {
    return test.map(({ title, content }, index) => {
      return (
        <Accordion key={index} header={title}>
          {content}
        </Accordion>
      );
    });
  }, [test]);

  return (
    <Styles.ContainerSecondary>
      <GlobalStyles.TitleContainer>
        <GlobalStyles.Title>Criação de Conta</GlobalStyles.Title>
        <GlobalStyles.SubTitle>
          Criar perfil de colaborador
        </GlobalStyles.SubTitle>
      </GlobalStyles.TitleContainer>
      <Styles.Content>
        <Styles.CheckBox>
          <Checkbox
            value={isAdmin}
            setValue={setIsAdmin}
            label="Administrador"
          />
        </Styles.CheckBox>
        <Styles.AccordionContainer>{mapAccordion}</Styles.AccordionContainer>
      </Styles.Content>
    </Styles.ContainerSecondary>
  );
};

export default RegisterPermissionGroup;
