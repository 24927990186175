import styled from "styled-components";

export const Container = styled.span`
  display: flex;
  flex-direction: column;
  height: 55px;
`;

export const Input = styled.input<{ error?: boolean }>`
  font-size: 16px;
  width: 25rem;
  height: 48px;
  padding: 15px 10px;
  border-radius: 8px;
  border: 1px solid var(--gray2);
  background-color: transparent;

  :focus {
    outline: none;
    border: 1px solid var(--primary);
  }

  ${({ error }) =>
    error &&
    `
    border: 1px solid var(--error);

    :focus {
    outline: none;
    border: 1px solid var(--error);
  }
  `}
`;

export const HelperText = styled.span`
  font-size: 11px;
  color: var(--error);
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--gray5);
`;
