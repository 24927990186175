import { IPermissions, IReponse } from "../interfaces/Group.structure";
import { ICreateUser, ICreateUserResponse, IError, IUser } from "../interfaces/User.structure";
import api from "./api";
class ProfileService {
  /**
   * @description Create a new user
   * @returns {Promise<ICreateUserResponse | false>} An object when requisition is true or false in error
   */
  async createProfile(body:ICreateUser) {
    try {
      const { data } = await api.post(`/profile`, body);
      return data;
    } catch (error) {
      return {
        error: true,
        message: error.message
      };
    }
  }
  /**
   * @description Get all permissions levels
   * @returns {Promise<IPermissionLevels | false>} An object when requisition is true or false in error
   */
  async patchProfile(position:string,permissions:IPermissions[],logged_user_id,logged_user_password,id:string){
    try {
      const { data } = await api.patch(`/profile/${id}`, {
        position,
        permissions,
        logged_user_id,
        logged_user_password
      });
      return data;
    } catch (error) {
      return {
        error: true,
        message: error.response.data.message as IError
      };
    }
  }
}

export default new ProfileService();
