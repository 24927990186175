import React from "react";
import * as Interface from "../Inputs.structure";
import * as Styles from "./styles";

const NameInput = ({
  label,
  onChange,
  value,
  placeholder,
}: Interface.IInput) => {
  const error = !!(!value.match(/^[a-zA-Z]+(\s[a-zA-Z]+)/) && value);
  return (
    <Styles.Container>
      <Styles.Title>{label}</Styles.Title>
      <Styles.Input
        value={value.replace(/[^a-zA-Z," "]/g, "")}
        onChange={onChange}
        error={error}
        placeholder={placeholder}
        maxLength={40}
      />
      {error && (
        <Styles.HelperText>Preencha o nome completo!</Styles.HelperText>
      )}
    </Styles.Container>
  );
};

export default NameInput;
