import styled from "styled-components";
import { IGroupCardStyle } from "./interfaces.structure";
import {IoIosArrowDropdownCircle as ArrowDown, IoIosArrowDroprightCircle as ArrowForward} from 'react-icons/io';
import {FaFileInvoiceDollar} from 'react-icons/fa';

export const Container = styled.div`
  width:100%;
  height: fit-content;
  height: 70px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;

  @media screen and (max-width: 1350px) {
    height: 60px;
  }
`

export const TitleContainer = styled.div<IGroupCardStyle>`
  width:100%;
  height:70px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  background-color: var(--white);
  padding: 0 20px;
  border-radius: ${({ isOpen }) => isOpen ? "8px 8px 0 0" : "8px"};
`

export const TitleIcon = styled.div`
  width:fit-content;
  max-width: 30%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  gap: 10px;

  & > span {
    font-size: 1rem;
    font-weight: bold;
    color: var(--gray5);
  }
`

export const Groups = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  overflow: scroll;
`

export const File = styled(FaFileInvoiceDollar)`
  color: var(--gray5);
`

export const CollapseContainer = styled.div<IGroupCardStyle>`
  width:100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items:center;
  background-color: var(--white);
`

export const PermissionsContainer = styled.div`
  width:100%;
  height:70%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  gap:10px;
`

export const PermissionType = styled.div`
  width:100%;
  height: fit-content;
  display:flex;
  justify-content:flex-end;
  align-items:center;
  gap:50px;
  padding: 0 40px 0 0;
  & > span {
    color: var(--gray5);
    font-size: 0.8rem;
    font-weight:bold;
    font-family: 'Roboto', sans-serif;
  }
`

export const Permissions = styled.div`
  width:100%;
  height: fit-content;
  max-height:75%;
  min-height:40px;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
`

export const ModalSearch = styled.div`
  min-width: 40%;
  min-height: 45px;
  border-radius:8px;
  border: 1px solid var(--gray3);
  margin-top:30px;
  display:flex;
  justify-content: flex-start;
  align-items:center;
  padding: 0 15px;
  gap:10px;
  font-family: 'Roboto', sans-serif;

  & > input {
    color: var(--gray3);
    font-size: 0.8rem;
    font-weight:bold;
    font-family: 'Roboto', sans-serif;
    border:none;
    outline:none;
    background-color:transparent;
    width:100%;
    height:100%;

    &::placeholder {
      color: var(--gray4);
      font-size: 0.7rem;
      font-weight:bold;
    }
  }
`

export const CollapseContent = styled.div<IGroupCardStyle>`
  width:100%;
  max-height: ${({ isOpen }) => isOpen ? "1000px" : "0px"};
  display:flex;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  padding: ${({ isOpen }) => isOpen ? "0 20px 20px 20px" : "0 20px"};
  background-color: var(--white);
`


export const CollapseIconForward = styled(ArrowForward)<IGroupCardStyle>`
  color: var(--gray4);
  transform: ${({ isOpen }) => isOpen ? "rotate(90deg)" : "rotate(0deg)"};
  transition: transform 0.5s ease-in-out;
`

export const CollapseIconDown = styled(ArrowDown)`
  color: var(--gray4);
`;


