import styled, { keyframes } from "styled-components";

const loader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const LoadingContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: var(--secondary);
  animation: ${loader} 1s ease-out infinite;  
`