import { IPermissions, IReponse } from "../interfaces/Group.structure";
import api from "./api";

class PermissionsService {
  /**
   * @description Get all permissions levels
   * @returns {Promise<IPermissionLevels | false>} An object when requisition is true or false in error
   */
  async getPermissions() {
    try {
      const { data } = await api.get("/permissions");
      return data;
    } catch (error) {
      return {
        error: true,
        message: error.message
      };
    }
  }

  async getUserPermissions(id: string) {
    try {
      const { data } = await api.get(`/permissions/${id}`);
      return data;
    } catch (error) {
      return false;
    }
  }
}

export default new PermissionsService();
