import React from "react";
import { IAccordion } from "./accordion.structure";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import * as Styles from "./styles";

const Accordion = ({ header, children }: IAccordion) => {
  const [isActive, setIsActive] = React.useState<boolean>(false);

  return (
    <Styles.accordionContent>
      <Styles.accordionTitleContent onClick={() => setIsActive(!isActive)}>
        <Styles.valueContent>
          <Styles.accordionTitleSubContent>
            <div>{header}</div>
          </Styles.accordionTitleSubContent>

          <Styles.iconContent>
            {isActive ? (
              <IoIosArrowDropdownCircle
                style={{ width: 25, height: 25, color: "var(--gray4)" }}
              />
            ) : (
              <IoIosArrowDroprightCircle
                style={{
                  width: 25,
                  height: 25,
                  color: "var(--gray4)",
                }}
              />
            )}
          </Styles.iconContent>
        </Styles.valueContent>
      </Styles.accordionTitleContent>

      {isActive && (
        <>
          <Styles.divider />
          <Styles.accordionSubContent>{children}</Styles.accordionSubContent>
        </>
      )}
      <Styles.bottom />
    </Styles.accordionContent>
  );
};

export default Accordion;
