import React from 'react'
import { Radio, RadioCard } from "../../components/Inputs/Radio";
import Cookie from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import { CreateGroupButton, Container, Content, CreateGroupContainer, Header, GroupsContainer, GroupCard, GroupRadio, Button, SuccessContainer, SuccessDescription, SuccessButton, SkeletonSpan, Back, ArrowBack } from "./styles";
import PermissionsModal from '../../components/PermissionsModal';
import { IAssignGroupProps, IGroupPermissions } from './interfaces.structure';
import { IGroups } from '../../interfaces/Group.structure';
import ProfileService from '../../services/Profile';
import {FaCheck as Check} from 'react-icons/fa';
import Loading from '../../components/Loading';
import { LinkAnchor } from '../../components/General/styles';
import { CloseIcon, Modal, ModalButton, ModalContent, ModalHeader, ModalInput } from '../../pages/CreateGroup/styles';
import { usePagination } from '../../hooks/usePagination';
import Pagination from '../Pagination';
import { Toasty } from '../Toasty';
import { ErrorType } from '../../pages/CreateGroup/interfaces.structure';

export default function AssignGroup({groups,permissions,selectedUser,getGroups,setSelectedUser,setSteps,redirectTo,headerTitle = 'Criação de conta'}: IAssignGroupProps) {
  const [shouldShowModal, setShouldShowModal] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>('');
  const [shouldDisplayModal, setShouldDisplayModal] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [permissionsModalGroup, setPermissionsModalGroup] = React.useState<IGroups>();
  const [hasUpdatedPermissions, setHasUpdatedPermissions] = React.useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = React.useState<IGroupPermissions[]>([]);
  const {currentPage,totalPages,changePage,backward,forward} = usePagination({totalItems: groups.total_number_groups,pageSize:10});
  const navigate = useNavigate();

  function handleSetModalGroup(group: IGroups) {
    setPermissionsModalGroup(group);
    setShouldShowModal(true);
  }

  const renderGroups = React.useMemo(() => {
    if(groups.groups_per_page.length <= 0) return <SkeletonSpan>Nenhum grupo encontrado</SkeletonSpan>;
    return groups.groups_per_page
    .sort()
    .map((item,index) => {
      return (
          <GroupCard key={item?.id}>
            <GroupRadio>
              <Radio isActive={selectedGroup === item.permissions} handleClick={() => setSelectedGroup(item.permissions)}/>
              <span>{item.name}</span>
            </GroupRadio>
            <span onClick={() => handleSetModalGroup(item)}>Editar permissões</span>
          </GroupCard>
      )
    })
  },[groups,selectedGroup])

  async function handleUpdateUserPermissions(){
    setIsLoading(true);
    const response = await ProfileService.patchProfile(selectedUser.position,selectedGroup,Cookie.get('id'),password,selectedUser.id);

    if(response.error) {
      showError();
      if(response.message.includes('Profile not found with informed id:')) {
        setErrorMessage('Colaborador não encontrado');
      } else {
        setErrorMessage('Senha incorreta');
      }
      
      setIsLoading(false);
      return;
    };
    setIsLoading(false);
    setHasUpdatedPermissions(true);
    setShouldDisplayModal(false);
  }

  React.useEffect(() => {
    getGroups(currentPage);
  },[currentPage])

  React.useEffect(() => {
    if(!selectedUser) {
      setSteps(1);
    }
  },[selectedUser])

  const showError = () => {
    const timeout = setTimeout(() => {
      setErrorMessage("");
    }, 4000);
    return () => clearTimeout(timeout);
  };

  const toasty = (label: string, type: ErrorType) => (
    <Toasty
      label={label}
      type={type}
      visible={errorMessage !== ""}
      onClick={() => setErrorMessage("")}
    />
  );


  return (
    <Container>
      {toasty(errorMessage, "error")}
      <Content>
        <Header>
          <span>{headerTitle}</span>
          <Back onClick={() => setSteps(prevValue => prevValue - 1)}>
            <ArrowBack size={20}/>
            <span>Voltar</span>
          </Back>
          <h1>Atribuir grupo ao colaborador</h1>
        </Header>
        {hasUpdatedPermissions 
          ? 
            <SuccessContainer>
              <Check size={250} color="var(--success)"/>
              <SuccessDescription>
                <h1>Permissões atribuidas com sucesso</h1>
                <span>Volte para atribuir outras permissões ou selecione outro colaborador.</span>
              </SuccessDescription>
              <SuccessButton onClick={() => {
                setSelectedUser(null);
                setSteps(1);
                navigate('/hub/manage-users')
              }}>Voltar</SuccessButton>
            </SuccessContainer>
          : 
            <>
              <CreateGroupContainer>
                <RadioCard isActive={selectedGroup === permissions} handleClick={() => setSelectedGroup(permissions)} label="Administrador"/>
                  <LinkAnchor to='/hub/create-group' state={{redirectTo: redirectTo}}>
                    <CreateGroupButton>
                      <span>Criar grupo</span>
                    </CreateGroupButton>
                  </LinkAnchor>
              </CreateGroupContainer>
              <GroupsContainer>
                {renderGroups}
              </GroupsContainer>

              <Pagination 
                totalPages={totalPages} 
                setCurrentPage={changePage} 
                currentPage={currentPage} 
                next={forward} 
                prev={backward}
              />

              <Button 
                disabled={selectedGroup.length <= 0 || !selectedUser?.id || isLoading} 
                onClick={() => setShouldDisplayModal(true)}
              > 
                {isLoading ? <Loading/> : "Atribuir permissões"}
              </Button>
            </>
        }
          {shouldDisplayModal && (
              <Modal>
                <ModalContent>
                  <CloseIcon 
                    onClick={() => setShouldDisplayModal(false)} 
                    size={35}
                  />

                  <ModalHeader>
                    <h1>Deseja editar as permissões do grupo </h1>
                    <span>Ao criar o grupo  você poderá imediatamente atribuir o grupo á outros colaboradores.</span>
                  </ModalHeader>

                  <ModalInput>
                    <label htmlFor="password" >Senha</label>
                    <input 
                      type="password" 
                      id="password" 
                      name="logged_user_password"
                      placeholder="Digite aqui a sua senha"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </ModalInput>

                  <ModalButton 
                    disabled={isLoading}
                    onClick={handleUpdateUserPermissions}
                  >
                    {isLoading ? <Loading /> : 'Confirmar edição'}
                  </ModalButton>
                </ModalContent>
              </Modal>
            )}
      </Content>
      {shouldShowModal && (
        <PermissionsModal 
          permissions={permissions} 
          group={permissionsModalGroup} 
          setShowModal={setShouldShowModal} 
          getGroups={getGroups} 
          currentPage={currentPage}
        />
      )}
    </Container>
  )

};

