import styled from "styled-components";
import {IoIosArrowBack as Return} from 'react-icons/io'
import { Link } from 'react-router-dom';
import { IPermissionStyle } from "../../pages/CreateGroup/interfaces.structure";


export const Container = styled.div`
  max-width:100vw;
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:var(--gray1);
`

export const Content = styled.div`
  width:50%;
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  padding-top: 40px;
  gap:15px;
  font-size:1rem;
  font-family:'Roboto',sans-serif;

  @media screen and (max-width: 1600px) {
    width:65%;
  }
`

export const Header = styled.div`
  width:100%;
  height:fit-content;
  max-height:10%;
  gap:20px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:flex-start;
  font-family: 'Fira Sans', sans-serif;
  margin-bottom:20px;
  color: var(--gray5);
  position:relative;

  & > h1 {
    font-size: 1.5rem;
    margin:0;
    font-weight:bold;
  }

  & > span {
    font-size: 1.25rem;
    font-weight:bold;
  }
`

export const Back = styled.div`
  position:absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  top: 40%;
  left: -100px;
  gap:5px;
  cursor: pointer;

  & > span {
    font-size: 0.9rem;
    font-weight:bold;
  }
`



export const CreateGroupContainer = styled.div`
  width:100%;
  height:fit-content;
  max-height:5%;
  display:flex;
  justify-content:space-between;
  align-items:flex-end;
`



export const CreateGroupButton = styled.div`
  width:200px;
  height:50px;
  border-radius:8px;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-size:1.1rem;
  display:flex;
  justify-content:center;
  align-items:center;
  font-weight:bold;
  font-family: 'Fira Sans', sans-serif;
  cursor: pointer;
  text-decoration: none;
`


export const GroupsContainer = styled.div`
  width:100%;
  min-height:65px;
  height:fit-content;
  max-height: 60%;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  gap:13px;
  overflow:auto;
`

export const SkeletonSpan = styled.span`
  color: var(--gray3);
  font-size: 0.9rem;
  font-weight: bold;
  align-self:flex-end;
`

export const GroupCard = styled.div`
  width:100%;
  height:65px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  border-radius:8px;
  padding: 0 25px 0 0;
  background-color:var(--white);

  & > span {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const GroupRadio = styled.div`
  height:100%;
  display:flex;
  justify-content:space-between;
  padding: 0 30px;
  align-items:center;
  gap:50px;
`

export const Modal = styled.div`
  width:100vw;
  height:100vh;
  background-color:rgba(0,0,0,0.5);
  display:flex;
  justify-content:center;
  align-items:center;
  position:fixed;
  
`

export const PermissionModal = styled.div`
  width:40%;
  height:85%;
  background-color: var(--white);
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  gap: 25px;
  border-radius:10px;
  padding: 0 50px;
  position:relative;

`

export const ModalDescriptionContainer = styled.div`
  width:100%;
  max-height:35%;
  height:fit-content;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
  gap:10px;

  & > h1 {
    font-size: clamp(1rem, 2vw, 1.3rem);
    font-weight:700;
    font-family: 'Fira Sans', sans-serif;
  }

  & > span {
    font-size: clamp(0.6rem, 1vw, 0.7rem);
  }
`

export const ModalDescription = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  font-family: 'Roboto', sans-serif;

  & > p {
    font-weight:bold;
  }
`

export const ModalSearch = styled.div`
  width: 40%;
  height: 45px;
  border-radius:8px;
  border: 1px solid var(--gray3);
  margin-top:30px;
  display:flex;
  justify-content: flex-start;
  align-items:center;
  padding: 0 15px;
  gap:10px;
  font-family: 'Roboto', sans-serif;

  & > input {
    color: var(--gray3);
    font-size: 0.8rem;
    font-weight:bold;
    font-family: 'Roboto', sans-serif;
    border:none;
    outline:none;
    background-color:transparent;
    width:100%;
    height:100%;

    &::placeholder {
      color: var(--gray4);
      font-size: 0.7rem;
      font-weight:bold;
    }
  }
`

export const PermissionsContainer = styled.div`
  width:100%;
  height:70%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  gap:10px;
`

export const PermissionType = styled.div`
  width:90%;
  height: fit-content;
  display:flex;
  justify-content:flex-end;
  align-items:center;
  gap:50px;
  padding: 0 15px 0 0;

  & > span {
    color: var(--gray5);
    font-size: 0.8rem;
    font-weight:bold;
    font-family: 'Roboto', sans-serif;
  }
`

export const Permissions = styled.div`
  width:100%;
  height: fit-content;
  max-height:75%;
  min-height:40px;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
`
export const Permission = styled.div<IPermissionStyle>`
  width:100%;
  height:40px;
  background-color: ${props => props.isOdd ? 'transparent' : 'var(--gray2)'};
  border-top-left-radius: ${props => props.isFirst ? '8px' : '0'};
  border-top-right-radius: ${props => props.isFirst ? '8px' : '0'};
  border-bottom-right-radius: ${props => props.isLast ? '8px' : '0'};
  border-bottom-left-radius: ${props => props.isLast ? '8px' : '0'};
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding: 0 50px 0 15px; 
`

export const PermissionCheckbox = styled.div`
  min-width:20%;
  width:fit-content;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  gap:80px;

  & > span {
    font-size: 0.8rem;
    white-space: nowrap;
  }
`

export const PermissionRadios = styled.div`
  width:fit-content;
  height:100%;
  display:flex;
  justify-content:center;
  gap:105px;
  align-items:center;
`

export const Button = styled.button`
  width: 350px;
  height: 50px;
  border-radius: 8px;
  background-color: var(--primary);
  outline: none;
  border: none;
  font-weight: bold;
  font-size:1rem;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
  display:flex;
  justify-content:center;
  align-items:center;
`

export const SuccessContainer = styled.div`
  width:100%;
  height:fit-content;
  height:70%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  gap:20px;
  padding: 30px;
  background-color: var(--white);
  border-radius: 15px;
`

export const SuccessDescription = styled.div`
  width:45%;
  height:45%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  gap:30px;
  color: var(--gray5);

  & > h1 {
    font-size:1.5rem;
    font-weight:bold;
    text-align:center;
    margin:0;
    width:fit-content;
    font-family: 'Fira-Sans', sans-serif;
    
  }

  & > span {
    font-size:1rem;
    text-align:center;
    font-family: 'Roboto', sans-serif;
  }
`

export const SuccessButton = styled.button`
  width: 40%;
  height: 50px;
  border-radius: 8px;
  background-color: var(--success);
  color: var(--white);
  outline: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
`

export const ArrowBack = styled(Return)`
  color: var(--gray5)
`