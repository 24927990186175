import { IPermissions } from "../interfaces/Group.structure";

export function groupByClaim(arr: IPermissions[]) {
  return arr?.reduce((group, item) => {
    const [claim] = item.claim.split('.')
    const acumulator = { ...group };
    acumulator[claim] = group[claim] ?? [];
    acumulator[claim].push(item);
    return acumulator;
  }, {});
}