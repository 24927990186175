import styled from "styled-components";

export const Container = styled.span`
  display: flex;
  flex-direction: column;
  height: 55px;
`;

export const Button = styled.button<{ color?: string }>`
  border: none;
  background: var(--primary);
  color: var(--gray5);
  font-weight: bold;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin-bottom: 8px;
  min-width: 21rem;
  height: 3rem;

  & > span {
    font-size: 1.2rem;
    font-weight: bold;
  }

  ${({ disabled }) =>
    disabled &&
    `
    background: var(--gray3);
  opacity: 0.8;
  `}
  ${({ color }) =>
    color &&
    `
    background: ${color};
    color: #fff;
  `}
`;

export const HelperText = styled.span`
  font-size: 11px;
  color: var(--error);
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
`;
