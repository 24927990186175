import React from 'react'
import api from "./services/api";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CreateUserProfile from "./pages/CreateUserProfile";
import RegisterPermissionGroup from "./components/CreateUserProfile/RegisterPermissionGroup";
import LoginService from "./services/Login";
import { mockedRoutes } from "./utils";
import CreateGroup from './pages/CreateGroup';
import GlobalStyle from './globalStyle';
import {PermissionContextProvider} from './contexts/permissionsContext'
import ManageUsers from './pages/ManageUsers';
import Cookie from 'js-cookie'

interface IUserInfo {
  id: string;
  token: string;
  refresh_token: string;
}

export default function Root(props) {
  const env_type = process.env.ENV_TYPE;
  const [userInfo, setUserInfo] = React.useState(
    props?.customProps?.auth_token || ({} as IUserInfo)
  );
  const routes =
    env_type === "local" ? mockedRoutes : props?.customProps?.routes;

  const localLogin = React.useCallback(async () => {
    const email = process.env.LOCAL_ENV_USER;
    const password = process.env.LOCAL_ENV_PASSWORD;

    try {
      const { token, id } = await LoginService.login(email, password);
      setUserInfo({ token, id });

      Cookie.set("token", token);
      Cookie.set("id", id);

    } catch (error) {
      return false;
    }
  }, []);

  React.useEffect(() => {
    document.title = "Admin | ADM - Permissions";
  }, []);

  React.useEffect(() => {
    if (env_type === "local") {
      localLogin();
    }
    
    api.defaults.headers["Authorization"] = `Bearer ${userInfo.token}`;
    api.defaults.headers["refresh_token"] = userInfo.refresh_token;
  }, []);



  return (
    <BrowserRouter>
      <PermissionContextProvider>
        <GlobalStyle />
        <Routes>
          <Route
            path={routes.accounts.createUsers}
            element={<CreateUserProfile />}
          />
          <Route
            path={routes.accounts.createGroup}
            element={<CreateGroup />}
          />
          <Route
            path={`${routes.accounts.createUsers}/define-permission-group`}
            element={<RegisterPermissionGroup />}
          />  
          <Route
            path={routes.accounts.manageUsers}
            element={<ManageUsers />}
          />
        </Routes>
      </PermissionContextProvider>
    </BrowserRouter>
  );
}
