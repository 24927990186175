import React from "react";
import * as Interface from "../Inputs.structure";
import * as Styles from "./styles";

const NameInput = ({
  label,
  onChange,
  value,
  placeholder,
}: Interface.IInput) => {
  return (
    <Styles.Container>
      <Styles.Title>{label}</Styles.Title>
      <Styles.Input
        value={value}
        onChange={onChange}
        error={false}
        placeholder={placeholder}
        maxLength={40}
      />
    </Styles.Container>
  );
};

export default NameInput;
