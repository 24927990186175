import { IGroup,IPageGroup} from "../interfaces/Group.structure";
import api from "./api";

class GroupServices {
  /**
   * @description Get all permissions group levels
   * @returns {Promise<IGroups[] | false>} An object when requisition is true or false in error
   */
  async getGroups(page = 0) {
    try {
      const { data } = await api.get(`/permissions-group?page=${page}`);
      return data;
    } catch (error) {
      return {
        error: true,
        message: error.message
      };
    }
  }

  /**
   * @description Create a new permission group
   * @returns {Promise<ICreateGroup | false>} An object when requisition is true or false in error
   */
  async createGroup(body:IGroup) {
    try {
      const { data } = await api.post("/permissions-group",body);
      return data;
    } catch (error) {
      return {
        error: true,
        message: error.message
      };
    }
  }

  /**
   * @description Update permissions array from a group 
   * @returns {Promise<ICreateGroup | false>} An object when requisition is true or false in error
   */
  async patchGroup(body:IGroup,groupId:string){
    try {
      const { data } = await api.patch(`/permissions-group/${groupId}`,body);
      return data;
    } catch (error) {
      return {
        error: true,
        message: error.message
      };
    }
  }
}

export default new GroupServices();
