import React from 'react';
import { IGroupCard } from './interfaces.structure';
import {BiSearch as Search} from 'react-icons/bi'
import { CollapseContent, CollapseIconForward, Container, File, Groups, ModalSearch, Permissions, PermissionsContainer, PermissionType, TitleContainer, TitleIcon } from './styles';
import { Permission, PermissionCheckbox, PermissionRadios } from '../../pages/CreateGroup/styles';
import CheckBox from '../Inputs/Checkbox';
import { Radio } from '../Inputs/Radio';
import { IPermission } from '../../interfaces/Permissions.structure';

const GroupCard = ({name,permissions,onCheckbox,newGroup,onRadioClick}:IGroupCard) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>('');


  function handleRadioState(flag: 'view' | 'edit', permission: IPermission, groupPermissions: IPermission[]) {
      const existingPermission = groupPermissions.find(item => item.id === permission.id);

      if(!existingPermission) return false;
      
      const [existingPermissionFlag] = existingPermission.claim.split('.').splice(-1);
      return existingPermissionFlag === flag;

  }

  function handlePermissionChange(permission: IPermission,flag: 'view' | 'edit') {
    const permissionWithoutFlag = permission.claim.split('.').splice(0,2).join('.');
    const newPermission = {...permission, claim: `${permissionWithoutFlag}.${flag}`};
    onRadioClick(newPermission)
  }

  const renderPermissions = React.useMemo(() => {
    return permissions
      ?.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
      .map((item,index) => {
      const lastItem = index === permissions.length - 1;
      const firstItem = index === 0;
      const isOdd = (index % 2) !== 0;
      const isDisabled = !(newGroup.permissions.find(permission => permission.id === item.id))
      return (
        <Permission isOdd={isOdd} isFirst={firstItem} isLast={lastItem} key={item?.id}>
          <PermissionCheckbox>
            <CheckBox isChecked={newGroup.permissions.filter(permission => permission.id === item.id).length > 0} onCheck={() => onCheckbox(item)}/>
            <span>{item.name}</span>
          </PermissionCheckbox>
          <PermissionRadios>
            <Radio 
              isDisabled={isDisabled} 
              isActive={handleRadioState('view',item,newGroup.permissions)} 
              handleClick={() => handlePermissionChange(item,'view')}
            />
            <Radio 
              isDisabled={isDisabled} 
              isActive={handleRadioState('edit',item,newGroup.permissions)} 
              handleClick={() => handlePermissionChange(item,'edit')}
            />
          </PermissionRadios>
        </Permission>
      )
    })
  },[permissions, newGroup, onCheckbox, onRadioClick,search])

  return (
  <Groups>
    <Container>
      <TitleContainer isOpen={isOpen}>
        <TitleIcon>
          <File size={25}/>
          <span>{name}</span>
        </TitleIcon>

        
        <CollapseIconForward
          isOpen={isOpen} 
          size={30} 
          onClick={() => setIsOpen(!isOpen)}
        />
      </TitleContainer>
    </Container>
      <CollapseContent isOpen={isOpen}>
        <ModalSearch >
          <Search size={25}/>
          <input 
            placeholder='Digite aqui o nome da permissão' 
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </ModalSearch>

        <PermissionsContainer>
          <PermissionType>
            <span>Somente visualizar</span>
            <span>Editar</span>
          </PermissionType>
          <Permissions>
            {renderPermissions}
          </Permissions>
        </PermissionsContainer>
      </CollapseContent>
  </Groups>
  )
}

export default GroupCard;

