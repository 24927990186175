import React from "react";

import { IRadioProps } from "./interfaces.structure";
import { Container, RadioButton, RadioCircle } from "./styles";

export const RadioCard = ({
  label = "",
  handleClick,
  isActive,
}: IRadioProps) => {
  return (
    <Container onClick={handleClick}>
      <RadioButton isActive={isActive}>
        <RadioCircle isActive={isActive} />
      </RadioButton>
      <span>{label}</span>
    </Container>
  );
};

export const Radio: React.FC<IRadioProps> = ({
  handleClick,
  isActive,
  isDisabled
}) => {
  return (
      <RadioButton isActive={isActive} onClick={handleClick} isDisabled={isDisabled}>
        <RadioCircle isActive={isActive} />
      </RadioButton>
  );
};
