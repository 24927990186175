import styled from "styled-components"
import {IoMdClose as Close} from 'react-icons/io'
import { IPermissionStyle } from "./interfaces.structure"



export const Container = styled.div`
  max-width:100vw;
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:var(--gray1);
`

export const Content = styled.div`
  width:50%;
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  padding-top: 40px;
  gap:15px;
  font-size:1rem;
  font-family:'Roboto',sans-serif;

  @media screen and (max-width: 1600px) {
    width:90%;
  }
`

export const Header = styled.div`
  width:100%;
  height:fit-content;
  max-height:10%;
  gap:20px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:flex-start;
  font-family: 'Fira Sans', sans-serif;
  margin-bottom:20px;
  color: var(--gray5);

  & > h1 {
    font-size: 1.5rem;
    margin:0;
    font-weight:bold;
  }

  & > span {
    font-size: 1.25rem;
    font-weight:bold;
  }
`

export const SearchContainer = styled.div`
  width:100%;
  height:fit-content;
  max-height:10%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
`

export const InputContainer = styled.div`
  width:45%;
  height:fit-content;
  max-height:15%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  gap:10px;
  align-self:flex-start;
  position:relative;
  
  & > input {
    width:100%;
    height:50px;
    border-radius:8px;
    border:1px solid var(--gray3);
    padding:0 10px;
    font-size:1rem;
    font-family:'Roboto',sans-serif;
    outline:none;
    color: var(--gray5);

    &:placeholder {
      color:var(--gray3);
    }
  }

  & > label {
    font-size:1rem;
    font-family:'Roboto',sans-serif;
    font-weight:bold;
    color: var(--gray5);
  }

  & > span {
    font-size:0.75rem;
    font-family:'Roboto',sans-serif;
    color:var(--error);
    position:absolute;
    bottom:-25px;
    left:5px;
  }
`

export const GroupsContainer = styled.div`
  width:100%;
  height:fit-content;
  min-height: 70px;
  max-height:40%;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
  gap:15px;
  overflow: auto;
  margin-top:30px;
`


export const Permission = styled.div<Partial<IPermissionStyle>>`
  width:100%;
  height:40px;
  background-color: ${props => props.isOdd ? 'transparent' : 'var(--gray2)'};
  border-top-left-radius: ${props => props.isFirst ? '8px' : '0'};
  border-top-right-radius: ${props => props.isFirst ? '8px' : '0'};
  border-bottom-right-radius: ${props => props.isLast ? '8px' : '0'};
  border-bottom-left-radius: ${props => props.isLast ? '8px' : '0'};
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding: 0 50px 0 15px; 
`

export const PermissionCheckbox = styled.div`
  min-width:20%;
  width:fit-content;
  height:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  gap:80px;

  & > span {
    white-space:nowrap;
    font-size: clamp(0.6rem, 1.2vw, 0.8rem);
    color: var(--gray5);
  }
`

export const PermissionRadios = styled.div`
  width:fit-content;
  height:100%;
  display:flex;
  justify-content:center;
  gap:105px;
  align-items:center;
`

export const NewGroupButton = styled.button`
  width: 350px;
  height: 50px;
  border-radius: 8px;
  background-color: var(--primary);
  outline: none;
  border: none;
  font-weight: bold;
  font-size:1rem;
  cursor: pointer;
`

export const Modal = styled.div`
  width:100%;
  height:100%;
  position:fixed;
  top:0;
  left:0;
  background-color:rgba(0,0,0,0.5);
  display:flex;
  justify-content:center;
  align-items:center;
`

export const ModalContent = styled.div`
  width:750px;
  height:570px;
  max-height: 80%;
  background-color:var(--white);
  border-radius:15px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding: 50px 0 20px 0;
  gap: 100px;
  position:relative;
`

export const CloseIcon = styled(Close)`
  position:absolute;
  top:10px;
  right:10px;
  cursor:pointer;
  color:var(--gray3);
`

export const ModalHeader = styled.div`
  width:100%;
  height:fit-content;
  max-height:10%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  gap:20px;
  color: var(--gray5);
  & > h1 {
    font-size:1.5rem;
    font-weight:bold;
    margin:0;
    width:fit-content;
    max-width:35%;
    text-align:center;
  }

  & > span {
    font-size:1rem;
    width:90%;
  }
`

export const ModalInput = styled.div`
  width:45%;
  height:fit-content;
  max-height:15%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  gap:10px;

  & > input {
    width:100%;
    height:45px;
    border-radius:8px;
    border:1px solid var(--gray3);
    padding:0 10px;
    font-size:1rem;
    font-family:'Roboto',sans-serif;
    outline:none;

    &:placeholder {
      color:#a8a8a8;
    }
  }

  & > label {
    font-size:0.8rem;
    font-family:'Roboto',sans-serif;
    font-weight:bold;

  }
`

export const ModalButton = styled.button`
  width: 40%;
  min-height: 50px;
  border-radius: 8px;
  background-color: var(--success);
  color: var(--white);
  outline: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
`

export const SuccessContainer = styled.div`
  width:100%;
  height:fit-content;
  height:80%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  gap:20px;
  padding: 30px;
  background-color: var(--white);
  border-radius: 15px;


`

export const SuccessDescription = styled.div`
  width:45%;
  height:45%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  gap:30px;
  color: var(--gray5);

  & > h1 {
    font-size:1.5rem;
    font-weight:bold;
    text-align:center;
    margin:0;
    width:fit-content;
    font-family: 'Fira-Sans', sans-serif;
  }

  & > span {
    font-size:1rem;
    text-align:center;
    font-family: 'Roboto', sans-serif;
  }
`

export const SuccessButton = styled.button`
  min-width: 250px;
  max-width: 50%;
  height: 50px;
  border-radius: 8px;
  background-color: var(--success);
  color: var(--white);
  outline: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
`
